"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var defaultLogger = makeLogger();
var _default = defaultLogger;
exports.default = _default;

function makeLogger(label) {
  var LOG_INFO = false;
  var LOG_ERROR = true;

  function logger() {
    logger.info.apply(logger, arguments);
  }

  logger.info = function logInfo() {
    if (LOG_INFO) {
      var _window$console;

      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      if (label) {
        var _context;

        args[0] = (0, _concat.default)(_context = "[".concat(label, "] ")).call(_context, args[0]);
      }

      (_window$console = window.console).log.apply(_window$console, args);
    }
  };

  logger.error = function logError() {
    if (LOG_ERROR) {
      var _window$console2;

      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      if (label) {
        var _context2;

        args[0] = (0, _concat.default)(_context2 = "[".concat(label, "] ")).call(_context2, args[0]);
      }

      (_window$console2 = window.console).error.apply(_window$console2, args);
    }
  };

  logger.assert = function assert(condition) {
    var _context3;

    if (condition) {
      return;
    }

    for (var _len3 = arguments.length, args = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
      args[_key3 - 1] = arguments[_key3];
    }

    this.error.apply(this, (0, _concat.default)(_context3 = ['Assertion failed.']).call(_context3, args));
  };

  logger.setLevel = function setLevel(newLevel) {
    if (newLevel === 0) {
      LOG_ERROR = false;
      LOG_INFO = false;
    } else if (newLevel === 1) {
      LOG_ERROR = true;
      LOG_INFO = false;
    } else if (newLevel === 2) {
      LOG_ERROR = true;
      LOG_INFO = true;
    }
  };

  logger.namespace = function namespace(newLabel) {
    var l = newLabel;

    if (label) {
      var _context4;

      l = (0, _concat.default)(_context4 = "[".concat(label, "][")).call(_context4, l, "]");
    }

    return makeLogger(l);
  };

  return logger;
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["Logger"]
window._kiwi_exports.libs.Logger = exports.default ? exports.default : exports;
