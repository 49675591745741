"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _setTimeout2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/set-timeout"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _bind = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/bind"));

var _reverse = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reverse"));

var _sort = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/sort"));

var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));

var _flags = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/flags"));

var _set = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/set"));

var _lodash = _interopRequireDefault(require("lodash"));

var _strftime = _interopRequireDefault(require("strftime"));

var _Logger = _interopRequireDefault(require("@/libs/Logger"));

var _BufferKey = _interopRequireDefault(require("./BufferKey"));

var _MessageListMessageCompact = _interopRequireDefault(require("./MessageListMessageCompact"));

var _MessageListMessageModern = _interopRequireDefault(require("./MessageListMessageModern"));

var _MessageListMessageInline = _interopRequireDefault(require("./MessageListMessageInline"));

var _LoadingAnimation = _interopRequireDefault(require("./LoadingAnimation.vue"));

require('@/libs/polyfill/Element.closest');

var log = _Logger.default.namespace('MessageList.vue'); // If we're scrolled up more than this many pixels, don't auto scroll down to the bottom
// of the message list


var BOTTOM_SCROLL_MARGIN = 50;
var _default = {
  components: {
    BufferKey: _BufferKey.default,
    MessageListMessageModern: _MessageListMessageModern.default,
    MessageListMessageCompact: _MessageListMessageCompact.default,
    MessageListMessageInline: _MessageListMessageInline.default,
    LoadingAnimation: _LoadingAnimation.default
  },
  props: ['buffer'],
  data: function data() {
    return {
      auto_scroll: true,
      chathistoryAvailable: true,
      hover_nick: '',
      message_info_open: null,
      timeToClose: false,
      startClosing: false,
      selectedMessages: new _set.default()
    };
  },
  computed: {
    thisMl: function thisMl() {
      return this;
    },
    listType: function listType() {
      if (this.$state.setting('messageLayout')) {
        log.info('Deprecation Warning: The config option \'messageLayout\' has been moved to buffers.messageLayout');
      }

      return this.buffer.setting('messageLayout') || this.$state.setting('messageLayout');
    },
    useExtraFormatting: function useExtraFormatting() {
      // Enables simple markdown formatting
      return this.buffer.setting('extra_formatting');
    },
    shouldShowChathistoryTools: function shouldShowChathistoryTools() {
      // Only show it if we're connected
      if (this.buffer.getNetwork().state !== 'connected') {
        return false;
      }

      var isCorrectBufferType = this.buffer.isChannel() || this.buffer.isQuery();
      var isSupported = !!this.buffer.getNetwork().ircClient.chathistory.isSupported();
      return isCorrectBufferType && isSupported && (0, _flags.default)(this.buffer).chathistory_available;
    },
    shouldRequestChannelKey: function shouldRequestChannelKey() {
      return this.buffer.getNetwork().state === 'connected' && this.buffer.isChannel() && (0, _flags.default)(this.buffer).channel_badkey;
    },
    ourNick: function ourNick() {
      return this.buffer ? this.buffer.getNetwork().nick : '';
    },
    filteredMessages: function filteredMessages() {
      var network = this.buffer.getNetwork();
      var currentNick = network.nick;
      var bufferMessages = this.buffer.getMessages(); // Hack; We need to make vue aware that we depend on buffer.message_count in order to
      // get the messagelist to update its DOM, as the change of message_count alerts
      // us that the messages have changed. This is done so that vue does not have to make
      // every emssage reactive which gets very expensive.

      /* eslint-disable no-unused-vars */

      var ignoredVar = this.buffer.message_count;
      var messages = (0, _slice.default)(bufferMessages).call(bufferMessages, 0, bufferMessages.length);
      (0, _sort.default)(messages).call(messages, function (a, b) {
        if (a.time > b.time) {
          return 1;
        } else if (b.time > a.time) {
          return -1;
        }

        return a.instance_num > b.instance_num ? 1 : -1;
      });
      var list = [];
      var maxSize = this.buffer.setting('scrollback_size');
      var showJoinParts = this.buffer.setting('show_joinparts');
      var showTopics = this.buffer.setting('show_topics');
      var showNickChanges = this.buffer.setting('show_nick_changes');
      var showModeChanges = this.buffer.setting('show_mode_changes');

      for (var i = messages.length - 1; i >= 0 && list.length < maxSize; i--) {
        if (!showJoinParts && messages[i].type === 'traffic') {
          continue;
        }

        if (!showTopics && messages[i].type === 'topic') {
          continue;
        }

        if (!showNickChanges && messages[i].type === 'nick') {
          continue;
        }

        if (!showModeChanges && messages[i].type === 'mode') {
          continue;
        } // Ignored users have the ignore flag set


        if (messages[i].ignore) {
          continue;
        } // Don't show the first connection message. Channels are only interested in
        // the joining message at first. Dis/connection messages are only relevant here
        // if the dis/connection happens between messages (during a conversation)


        if (messages[i].type === 'connection' && i === 0) {
          continue;
        } // When we join a channel the topic is usually sent next. But this looks
        // ugly when rendered. So we switch the topic + join messages around so
        // that the topic is first in the message list.


        if (messages[i].type === 'topic' && messages[i - 1] && messages[i - 1].type === 'traffic' && messages[i - 1].nick === currentNick) {
          list.push(messages[i - 1]);
          list.push(messages[i]);
          i--;
        } else {
          list.push(messages[i]);
        }
      }

      return (0, _reverse.default)(list).call(list);
    },
    shouldShowJoiningLoader: function shouldShowJoiningLoader() {
      return this.buffer.isChannel() && this.buffer.enabled && !this.buffer.joined && this.buffer.getNetwork().state === 'connected';
    },
    isIos: function isIos() {
      return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    }
  },
  watch: {
    buffer: function buffer(newBuffer) {
      var _this = this;

      if (!newBuffer) {
        return;
      }

      this.message_info_open = null;

      if (this.buffer.getNetwork().state === 'connected') {
        (0, _flags.default)(newBuffer).has_opened = true;
      }

      this.$nextTick(function () {
        _this.scrollToBottom();
      });
    },
    'buffer.message_count': function bufferMessage_count() {
      var _this2 = this;

      this.$nextTick(function () {
        _this2.maybeScrollToBottom();
      });
    }
  },
  mounted: function mounted() {
    var _this3 = this;

    this.addCopyListeners();
    this.$nextTick(function () {
      _this3.scrollToBottom();
    });
    this.listen(this.$state, 'mediaviewer.opened', function () {
      _this3.$nextTick(_this3.maybeScrollToBottom.apply(_this3));
    });
    this.listen(this.$state, 'messagelist.scrollto', function (opt) {
      if (opt && opt.id) {
        _this3.maybeScrollToId(opt.id);
      }
    });
  },
  methods: {
    isHoveringOverMessage: function isHoveringOverMessage(message) {
      return message.nick && message.nick.toLowerCase() === this.hover_nick.toLowerCase();
    },
    toggleMessageInfo: function toggleMessageInfo(message) {
      if (!message) {
        this.message_info_open = null;
      } else if (this.message_info_open === message) {// It's already open, so don't do anything
      } else if (this.canShowInfoForMessage(message)) {
        var _context;

        // If in the process of selecting text, don't show the info box
        var sel = window.getSelection();

        if (sel.rangeCount > 0) {
          var range = sel.getRangeAt(0);

          if (range && !range.collapsed) {
            return;
          }
        }

        this.message_info_open = message;
        this.$nextTick((0, _bind.default)(_context = this.maybeScrollToBottom).call(_context, this));
      }
    },
    shouldShowUnreadMarker: function shouldShowUnreadMarker(idx) {
      var previous = this.filteredMessages[idx - 1];
      var current = this.filteredMessages[idx];
      var lastRead = this.buffer.last_read;

      if (!lastRead) {
        return false;
      } // If the last message has been read, and this message not read


      if (previous && previous.time < lastRead && current.time > lastRead) {
        return true;
      }

      return false;
    },
    shouldShowDateChangeMarker: function shouldShowDateChangeMarker(idx) {
      var previous = this.filteredMessages[idx - 1];
      var current = this.filteredMessages[idx];

      if (!previous) {
        return false;
      } // If the last message has been read, and this message not read


      if (new Date(previous.time).getDay() !== new Date(current.time).getDay()) {
        return true;
      }

      return false;
    },
    canShowInfoForMessage: function canShowInfoForMessage(message) {
      var showInfoForTypes = ['privmsg', 'notice', 'action'];
      return (0, _indexOf.default)(showInfoForTypes).call(showInfoForTypes, message.type) > -1;
    },
    bufferSetting: function bufferSetting(key) {
      return this.buffer.setting(key);
    },
    formatTime: function formatTime(time) {
      return (0, _strftime.default)(this.buffer.setting('timestamp_format') || '%T', new Date(time));
    },
    formatTimeFull: function formatTimeFull(time) {
      var format = this.buffer.setting('timestamp_full_format');
      return format ? (0, _strftime.default)(format, new Date(time)) : new Date(time).toLocaleString();
    },
    formatMessage: function formatMessage(message) {
      return message.toHtml(this);
    },
    isMessageHighlight: function isMessageHighlight(message) {
      // Highlighting ourselves when we join or leave a channel is silly
      if (message.type === 'traffic') {
        return false;
      }

      return message.isHighlight;
    },
    userColour: function userColour(user) {
      if (user && this.bufferSetting('colour_nicknames_in_messages')) {
        return user.getColour();
      }

      return '';
    },
    openUserBox: function openUserBox(nick) {
      var user = this.$state.getUser(this.buffer.networkid, nick);

      if (user) {
        this.$state.$emit('userbox.show', user, {
          buffer: this.buffer
        });
      }
    },
    onListClick: function onListClick(event) {
      this.toggleMessageInfo();
    },
    onMessageDblClick: function onMessageDblClick(event, message) {
      clearTimeout(this.messageClickTmr);
      var userNick = event.target.getAttribute('data-nick');

      if (userNick) {
        this.$state.$emit('input.insertnick', userNick);
      }
    },
    onMessageClick: function onMessageClick(event, message, delay) {
      // Delaying the click for 200ms allows us to check for a second click. ie. double click
      // Quick hack as we only need double click for nicks, nothing else
      if (delay && event.target.getAttribute('data-nick')) {
        clearTimeout(this.messageClickTmr);
        this.messageClickTmr = (0, _setTimeout2.default)(this.onMessageClick, 200, event, message, false);
        return;
      }

      var isLink = event.target.tagName === 'A';
      var channelName = event.target.getAttribute('data-channel-name');

      if (channelName && isLink) {
        var network = this.buffer.getNetwork();
        this.$state.addBuffer(this.buffer.networkid, channelName);
        network.ircClient.join(channelName);
        this.$state.setActiveBuffer(this.buffer.networkid, channelName);
        return;
      }

      var userNick = event.target.getAttribute('data-nick');

      if (userNick && isLink) {
        this.openUserBox(userNick);
        return;
      }

      var url = event.target.getAttribute('data-url');

      if (url && isLink) {
        if (this.$state.setting('buffers.inline_link_auto_previews')) {
          message.embed.type = 'url';
          message.embed.payload = url;
        } else {
          this.$state.$emit('mediaviewer.show', url);
        }
      }

      if (this.message_info_open && this.message_info_open !== message) {
        // Clicking on another message while another info is open, just close the info
        this.toggleMessageInfo();
        event.preventDefault();
        return;
      }

      if (this.$state.ui.is_touch && this.$state.setting('buffers.show_message_info')) {
        if (this.canShowInfoForMessage(message) && event.target.nodeName === 'A') {
          // We show message info boxes on touch screen devices so that the user has an
          // option to preview the links or do other stuff.
          event.preventDefault();
        }

        this.toggleMessageInfo(message);
      }
    },
    onThreadScroll: function onThreadScroll() {
      var el = this.$el;
      var scrolledUpByPx = el.scrollHeight - (el.offsetHeight + el.scrollTop);

      if (scrolledUpByPx > BOTTOM_SCROLL_MARGIN) {
        this.auto_scroll = false;
      } else {
        this.auto_scroll = true;
      }
    },
    scrollToBottom: function scrollToBottom() {
      if (this.isIos) {
        // On iOS using scrollIntoView causes issues with the keyboard pushing
        // the view upwards resulting in the input box being behind the keyboard
        this.$el.scrollTop = this.$el.scrollHeight;
        return;
      }

      this.$refs.ender.scrollIntoView(false);
    },
    maybeScrollToBottom: function maybeScrollToBottom() {
      var _this4 = this;

      if (!this.maybeScrollToBottom_throttled) {
        this.maybeScrollToBottom_throttled = _lodash.default.throttle(function () {
          if (_this4.auto_scroll) {
            _this4.scrollToBottom();
          }
        }, 500, {
          leading: true
        });
      }

      this.maybeScrollToBottom_throttled();
    },
    maybeScrollToId: function maybeScrollToId(id) {
      var messageElement = this.$el.querySelector('.kiwi-messagelist-message[data-message-id="' + id + '"]');

      if (messageElement && messageElement.offsetTop) {
        this.$el.scrollTop = messageElement.offsetTop;
        this.auto_scroll = false;
      }
    },
    restrictTextSelection: function restrictTextSelection() {
      // Prevents the selection cursor escaping the message list.
      document.querySelector('body').classList.add('kiwi-unselectable');
      this.$el.style.userSelect = 'text';
    },
    unrestrictTextSelection: function unrestrictTextSelection() {
      // Allows all page elements to be selected again.
      document.querySelector('body').classList.remove('kiwi-unselectable');
      this.$el.style.userSelect = 'auto';
    },
    removeSelections: function removeSelections() {
      var removeNative = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (this.selectedMessages.size > 0) {
        this.selectedMessages = new _set.default();
      }

      var selection = document.getSelection();

      if (removeNative && selection) {
        // stops the native browser selection being left behind after ctrl+c
        selection.removeAllRanges();
      }
    },
    addCopyListeners: function addCopyListeners() {
      var _this5 = this;

      // Better copy pasting
      var LogFormatter = function LogFormatter(msg) {
        var _context2;

        var text = '';

        switch (msg.type) {
          case 'privmsg':
            text = (0, _concat.default)(_context2 = "<".concat(msg.nick, "> ")).call(_context2, msg.message);
            break;

          case 'nick':
          case 'mode':
          case 'action':
          case 'traffic':
            text = "".concat(msg.message);
            break;

          default:
            text = msg.message;
        }

        if (text.length) {
          var _context3;

          return (0, _concat.default)(_context3 = "[".concat(new Date(msg.time).toLocaleTimeString({
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          }), "] ")).call(_context3, text);
        }

        return null;
      };

      var copyData = '';
      var selecting = false;
      var selectionChangeOff = null;
      this.listen(document, 'selectstart', function (e) {
        if (!_this5.$el.contains(e.target)) {
          // Selected elsewhere on the page
          copyData = '';

          _this5.removeSelections();

          return;
        }

        _this5.removeSelections();

        selectionChangeOff = _this5.listen(document, 'selectionchange', onSelectionChange);
      });
      this.listen(document, 'mouseup', function (e) {
        selectionChangeOff && selectionChangeOff();

        _this5.unrestrictTextSelection();

        if (selecting) {
          e.preventDefault();
        }

        selecting = false;
      });

      var onSelectionChange = function onSelectionChange(e) {
        if (!_this5.$el) {
          return true;
        }

        copyData = ''; // Store the text data to be copied in this.

        var selection = document.getSelection();

        if (!selection || !selection.anchorNode || !selection.anchorNode.parentNode.closest('.' + _this5.$el.className)) {
          _this5.unrestrictTextSelection();

          _this5.removeSelections();

          return true;
        }

        _this5.removeSelections(); // Prevent the selection escaping the message list


        _this5.restrictTextSelection();

        if (selection.rangeCount > 0) {
          var _context4, _context5;

          selecting = true;
          var firstRange = selection.getRangeAt(0);
          var lastRange = selection.getRangeAt(selection.rangeCount - 1); // Traverse the DOM to find messages in selection

          var startNode = firstRange.startContainer.parentNode.closest('[data-message-id]');
          var endNode = lastRange.endContainer.parentNode.closest('[data-message-id]');

          if (!endNode) {
            // If endContainer isn't in messagelist then mouse has been dragged outside
            // Set the end node to last in the message list
            endNode = _this5.$el.querySelector('.kiwi-messagelist-item:last-child');
          }

          if (!startNode || !endNode || startNode === endNode) {
            return true;
          }

          var node = startNode;
          var messages = [];

          var allMessages = _this5.buffer.getMessages();

          var selectedMessagesSize = _this5.selectedMessages.size;

          var finder = function finder(m) {
            return m.id.toString() === node.attributes['data-message-id'].value;
          };

          var i = 0;

          while (node) {
            // This could be more efficent with an id->msg lookup
            var msg = (0, _find.default)(allMessages).call(allMessages, finder);

            if (msg) {
              // Add to a list of selected messages
              _this5.selectedMessages.add(msg.id);

              messages.push(msg);
            }

            if (node === endNode) {
              node = null;
            } else {
              var nextNode = node.closest('[data-message-id]').parentNode.nextElementSibling;
              node = nextNode && nextNode.querySelector('[data-message-id]');
            }
          } // Replace the set so the MessageList updates, but only if it's changed.


          if (selectedMessagesSize !== _this5.selectedMessages.size) {
            _this5.selectedMessages = new _set.default(_this5.selectedMessages);
          } // Iterate through the selected messages, format and store as a
          // string to be used in the copy handler


          copyData = (0, _map.default)(_context4 = (0, _filter.default)(_context5 = (0, _sort.default)(messages).call(messages, function (a, b) {
            return a.time > b.time ? 1 : -1;
          })).call(_context5, function (m) {
            var _context6;

            return (0, _trim.default)(_context6 = m.message).call(_context6).length;
          })).call(_context4, LogFormatter).join('\r\n');
        } else {
          _this5.unrestrictTextSelection();
        }

        return false;
      };

      this.listen(document, 'copy', function (e) {
        if (!copyData || !copyData.length) {
          // Just do a normal copy if no special data
          return true;
        }

        if (navigator.clipboard) {
          // Supports Clipboard API
          navigator.clipboard.writeText(copyData);
        } else {
          var input = document.createElement('textarea');
          document.body.appendChild(input);
          input.innerHTML = copyData;
          input.select();
          document.execCommand('copy');
          document.body.removeChild(input);
        }

        return true;
      });
    },
    // Move a messages embeded content to the main media preview
    openEmbedInPreview: function openEmbedInPreview(message) {
      // First open the embed in the main media preview
      var embed = message.embed;

      if (embed.type === 'url') {
        this.$state.$emit('mediaviewer.show', embed.payload);
      } else if (embed.type === 'component') {
        this.$state.$emit('mediaviewer.show', {
          component: embed.payload
        });
      } // Remove the embed from the message


      embed.payload = null;
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["MessageList"]
window._kiwi_exports.components.MessageList = exports.default ? exports.default : exports;
