"use strict";
'kiwi public';
/**
 * batchedAdd prevents a flood of new inserts into state. After X inserts/sec, batch
 * each second worth of new items at the same time.
 */

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = batchedAdd;

var _setTimeout2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/set-timeout"));

function batchedAdd(singleFn, batchedFn) {
  var numInsertsSec = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 3;
  var queue = [];
  var numInLastSec = 0;
  var queueLoopTmr = null;
  var loopInterval = 1000;
  var checkSecRateTmr = null;

  function queueLoop() {
    numInLastSec = 0;

    if (queue.length) {
      // emptying queue before calling batchedFn in case that function triggers
      // code that needs to see that the queue has been processed.
      var q = queue;
      queue = [];
      batchedFn(q);
      queueLoopTmr = (0, _setTimeout2.default)(queueLoop, loopInterval);
    } else {
      queueLoopTmr = null;
    }
  }

  function maybeStartLoop() {
    if (!queueLoopTmr) {
      queueLoopTmr = (0, _setTimeout2.default)(queueLoop, loopInterval);
    }
  } // Reset numInLastSec after loopInterval. This allows enough time for the counter to
  // increase to detect batching. Only needs to run if we are not currently batching and
  // only needs to run once at a time.


  function resetAddCounter() {
    if (!queueLoopTmr && !checkSecRateTmr) {
      checkSecRateTmr = (0, _setTimeout2.default)(function () {
        checkSecRateTmr = null;

        if (!queueLoopTmr) {
          numInLastSec = 0;
        }
      }, loopInterval);
    }
  }

  function batchFn(item) {
    numInLastSec++; // If already queuing or we reached our limit on items/sec, queue the item

    if (queue.length || numInLastSec > numInsertsSec) {
      queue.push(item);
      maybeStartLoop();
    } else {
      singleFn(item);
      resetAddCounter();
    }
  }

  batchFn.queue = function getQueue() {
    return queue;
  };

  return batchFn;
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["batchedAdd"]
window._kiwi_exports.libs.batchedAdd = exports.default ? exports.default : exports;
