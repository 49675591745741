"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.orderBuffers = orderBuffers;
exports.getNextBuffer = getNextBuffer;
exports.getPreviousBuffer = getPreviousBuffer;

var _findIndex = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find-index"));

var _sort = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/sort"));

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _lodash = _interopRequireDefault(require("lodash"));

var _state = _interopRequireDefault(require("@/libs/state"));

function orderBuffers(buffers) {
  // Since vuejs will sort in-place and update views when .sort is called
  // on an array, clone it first so that we have a plain array to sort
  var list = (0, _map.default)(buffers).call(buffers, function (b) {
    return b;
  });
  list = (0, _filter.default)(_lodash.default).call(_lodash.default, list, function (buffer) {
    return !buffer.isServer();
  });
  list = (0, _sort.default)(list).call(list, function (a, b) {
    var order = 0;

    if (a.isChannel() && b.isQuery()) {
      order = -1;
    } else if (a.isQuery() && b.isChannel()) {
      order = 1;
    } else {
      order = a.name.localeCompare(b.name);
    }

    return order;
  });
  return list;
}

function getNextBuffer() {
  return getBufferFromDirection(1);
}

function getPreviousBuffer() {
  return getBufferFromDirection(-1);
}

function getBufferFromDirection(direction) {
  var state = (0, _state.default)();
  var network = state.getActiveNetwork();
  var buffer = state.getActiveBuffer();

  if (!network || !buffer) {
    return null;
  }

  var ordered = orderBuffers(network.buffers);
  var index = (0, _findIndex.default)(_lodash.default).call(_lodash.default, ordered, ['name', buffer.name]) + direction;

  if (index >= ordered.length || index < 0) {
    network = getNetworkFromDirection(direction);
    ordered = orderBuffers(network.buffers);
    buffer = direction === 1 ? ordered[0] : ordered[ordered.length - 1];
  } else {
    buffer = ordered[index];
  }

  return buffer;
}

function getNetworkFromDirection(direction) {
  var state = (0, _state.default)();
  var network = state.getActiveNetwork();

  for (var i = 0; i < state.networks.length; i++) {
    var index = (0, _findIndex.default)(_lodash.default).call(_lodash.default, state.networks, ['id', network.id]) + direction;

    if (index >= state.networks.length && state.networks.length >= 0) {
      network = state.networks[0];
    } else if (index < 0) {
      network = state.networks[state.networks.length - 1];
    } else {
      network = state.networks[index];
    }

    if (network.buffers.length > 1) {
      return network;
    }
  }

  return network;
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["bufferTools"]
window._kiwi_exports.libs.bufferTools = exports.default ? exports.default : exports;
